import React from "react";
import "./Menubar.css";
import "./Header.css";
// import logo from "../../assets/logo2r.png";
import "./Header.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import UGC from "../../assets/UGC.jpg";
import AICTE from "../../assets/AICTE.png";
import ISO from "../../assets/ISO.png";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ecaplogo from "../../assets/ecap-graphic.png";
import NaacImg from "../../assets/SMCE/Naac.jpeg";
import nba from "../../assets/SMCE/NBA.pdf";
import jntu from "../../assets/SMCE/JNTU.pdf";
import iso from "../../assets/SMCE/ISO.jpg"
import aicte from "../../assets/SMCE/AICTE.pdf"
import examsPortal from "../../assets/icons/exmstool.png"






import pdf from "../../assets/pdfsmittapalli/2019-20-BOG.pdf";
import pdf1 from "../../assets/pdfsmittapalli/2018-19-BOG.pdf";
import pdf2 from "../../assets/pdfsmittapalli/2017-18-governingbody.pdf";
import pdf3 from "../../assets/pdfsmittapalli/2020-21-BOG.pdf";
import pdf4 from "../../assets/pdfsmittapalli/2021-22-BOG.pdf";
// training&examcell
import pdf6 from "../../assets/pdfsmittapalli/2019-20-7training&placement.pdf";
import pdf7 from "../../assets/pdfsmittapalli/2020-21-4training&placement.pdf";
import pdf8 from "../../assets/pdfsmittapalli/2021-22-6training&placement2022.pdf";
import pdf9 from "../../assets/pdfsmittapalli/Mentoring-System-2021-22.pdf";
import pdf10 from "../../assets/pdfsmittapalli/Mentoring-System-2021-22.pdf";

import pdf11 from "../../assets/pdfsmittapalli/CGCELL.pdf";
import pdf12 from "../../assets/pdfsmittapalli/edcell.pdf";
import pdf13 from "../../assets/pdfsmittapalli/IIIC.pdf";

// EAO report
import pdf14 from "../../assets/pdfsmittapalli/EOA_Report_2019-20-4.PDF-1.pdf";
import pdf15 from "../../assets/pdfsmittapalli/EOA_Report_2020-21-1.pdf";
import pdf16 from "../../assets/pdfsmittapalli/EOA-Report_2018-19-1-1.pdf";
import pdf17 from "../../assets/pdfsmittapalli/EOA-Report-21-22-1.PDF-1.pdf";
import pdf18 from "../../assets/pdfsmittapalli/EOA-Report-2013-141-1.pdf";
import pdf19 from "../../assets/pdfsmittapalli/EOA-Report-2014-15-1.pdf";
import pdf20 from "../../assets/pdfsmittapalli/EOA-Report-2015-16-1.pdf";
import pdf21 from "../../assets/pdfsmittapalli/EOA-Report-2016-17-1.pdf";
import pdf22 from "../../assets/pdfsmittapalli/EOA-Report-2017-18-1.pdf";
import pdf23 from "../../assets/pdfsmittapalli/2006-AICTE-approval-1-1.pdf";
import pdf24 from "../../assets/pdfsmittapalli/aicte-approval-2008-9-1.pdf";
import pdf25 from "../../assets/pdfsmittapalli/aicte-approval-2009-2010-1.pdf";
import pdf26 from "../../assets/pdfsmittapalli/aicte-approval-2010-1-1.pdf";
import pdf27 from "../../assets/pdfsmittapalli/AICTE-Revised-Approval-Letter-2012-2013-u9-1.pdf";
// nba
import pdf28 from "../../assets/pdfsmittapalli/NBA-LETTER-2022.pdf";


import pdf29 from "../../assets/pdfsmittapalli/Sri-Mittapalli-College-of-Engineering20240131-1MBA.pdf";
import pdf30 from "../../assets/pdfsmittapalli/Sri-Mittapalli-College-of-Engineering20240131-ENGG.pdf";
import pdf31 from "../../assets/pdfsmittapalli/LETTER-FROM-NAAC-1.pdf";
import pdf32 from "../../assets/pdfsmittapalli/B.TECH-FEE-GO-23122020HE_MS48feestructre.pdf";
import pdf33 from "../../assets/pdfsmittapalli/7.1.3-extension.pdf";
import pdf34 from "../../assets/pdfsmittapalli/Mentoring-System-2021-22.pdf";

// aictesanc

import pdf35 from "../../assets/pdfsmittapalli/sanc-2017-18.pdf";
import pdf36 from "../../assets/pdfsmittapalli/sanc-2018-19.pdf";
import pdf37 from "../../assets/pdfsmittapalli/sanc-2019-20.pdf";
import pdf38 from "../../assets/pdfsmittapalli/sanc-2020-21.pdf";

import udpg from "../../assets/pdfsmittapalli/UG-PG-APPLICATION-2022.pdf";

// ug pg regulation
import ugR13 from "../../assets/pdfsmittapalli/ugR13.pdf";
import ugR16 from "../../assets/pdfsmittapalli/ugR16.pdf";
import ugR19 from "../../assets/pdfsmittapalli/ugR19.pdf";
import ugR20 from "../../assets/pdfsmittapalli/ugR20.pdf";
import pgR19 from "../../assets/pdfsmittapalli/pgR19.pdf";
import MTECHCSE from "../../Pdf/course_syllabus/PG/M.Tech CSE Syllabus.pdf";
import MTECHVLSEES from "../../Pdf/course_syllabus/PG/M.Tech VLSI&ES Syllabus.pdf";
import MR23MBA from "../../Pdf/course_syllabus/PG/MR23_MBA I, II SEM SYLLABUS.pdf";



//UG Course Sructure
import UGMr23BTECH from "../../Pdf/course_syllabus/UG/MR23_B.Tech_ SYLLABUS.pdf"
import UGR20AI from "../../Pdf/course_syllabus/UG/R20 AI Syllabus.pdf"
import UGR20CSE from "../../Pdf/course_syllabus/UG/R20 CSE Syllabus.pdf"
import UGR20DS from "../../Pdf/course_syllabus/UG/R20 DS Syllabus.pdf"
import UGR20ECE from "../../Pdf/course_syllabus/UG/R20 ECE Syllabus.pdf"
import UGR20IT from "../../Pdf/course_syllabus/UG/R20 IT Syllabus.pdf"


import fee from "../../assets/pdfsmittapalli/FeeStructure.docx";

import banner from "../../assets/mittapalliheader.png"


import { Link, useLocation } from "react-router-dom";
import Advertisement from "../CategoryB/Advertisement";

//Previus QP
import PQPBTech from "../../Pdf/PreviusQP/Autonomous_I B.Tech I Sem Question Papers_Feb-2024.pdf"
import PQPMTech from "../../Pdf/PreviusQP/Autonomous_M.Tech I Sem Question Papers_Feb-2024.pdf"
import PQPMBA from "../../Pdf/PreviusQP/Autonomous_MBA I Sem Question Papers_Feb-2024.pdf"

//Academic Regulation
import ARUGMR23 from "../../Pdf/AcademicRegulation/UG/MR23_B.Tech_Acedemic-Regulations.pdf"
import ARUGR20 from "../../Pdf/AcademicRegulation/UG/R20 B-Tech Course Regulations.pdf"

import ARPGMBA from "../../Pdf/AcademicRegulation/PG/MBA-MR23 (R19)-REGULATIONS.pdf"
import ARPGMTech from "../../Pdf/AcademicRegulation/PG/M.Tech-MR23 (R19)_regulations.pdf"

//Syllabus
import SBUGMR23 from "../../Pdf/Syllabus/UG/MR23_B.Tech_ SYLLABUS.pdf"
import SBUGAI from "../../Pdf/Syllabus/UG/R20 AI Syllabus.pdf"
import SBUGCSE from "../../Pdf/Syllabus/UG/R20 CSE Syllabus.pdf"
import SBUGDS from "../../Pdf/Syllabus/UG/R20 DS Syllabus.pdf"
import SBUGECE from "../../Pdf/Syllabus/UG/R20 ECE Syllabus.pdf"
import SBUGIT from "../../Pdf/Syllabus/UG/R20 IT Syllabus.pdf"

import SBPGMTechCSE from "../../Pdf/Syllabus/PG/M.Tech CSE Syllabus.pdf"
import SBPGMTechVLSI from "../../Pdf/Syllabus/PG/M.Tech VLSI&ES Syllabus.pdf"
import SBPGMBAMR23 from "../../Pdf/Syllabus/PG/MR23_MBA I, II SEM SYLLABUS.pdf"






const handleOpenPdf = (pdf) => {
    window.open(pdf, "_blank")
}

const Menubar = () => {

//   function changeColor() {
//     var logo = document.getElementById('notification_icon');
//     var colors = ['white', 'yellow',"red",];
    
//     var randomColor = colors[Math.floor(Math.random() * colors.length)];
//     logo.style.color = randomColor;
// }

// setInterval(changeColor, 100);

  const location = useLocation();
  return (
    <div>
      <div className="col-sm-12 ntf_banner_cnt">
        <div className="d-flex align-items-justify gap-5 ntf_bg_cnt ">
         
          <h5 className="ntm_prg"><b>APEAPCET/PGECET/ ECET/ICET Code :</b><b style={{fontWeight:900}}> MPLG</b></h5>
          
          <h5 className="ntm_prg"><b style={{fontWeight:700}}>College Code :(JNTUK)</b><b style={{fontWeight:900}}> U9</b> </h5>

          <p className="ntm_prgecap">
                <Link to="http://103.42.248.219/ecap/default.aspx" target="_blank"><img  className="ecap_logo" src={ecaplogo}></img></Link>
              </p>
              <div>
              <a >
                <Link to="http://smceexamcell.in/examcell/" target="_blank"><img className="examsPortal"  src={examsPortal}></img></Link>
                
              </a>
              </div>
 
            
           {/* <p className="ntm_prgecap">
            <a href="#">
              <img src={Banner} alt="ecap"/>
            </a>{" "}
          </p>   */}
         
        </div>
      </div>
      <div style={{ width: "100%"}}>
        <img src={banner} alt="Banner Sai Spurthi" className="sai_banner_img" />
      </div>
      <header>
        <input type="checkbox" id="menu-bar" />
        <label for="menu-bar">Menu</label>
        <nav class="navbar">
          <ul>
            {/* Home */}
            <li>
              <Link
                to="/"
                // className={
                //   location.pathname === "/"
                //     ? "nav-item nav-link active"
                //     : "nav-item nav-link"
                // }
              >
                HOME
              </Link>
            </li>
            {/* about us */}
            <li>
              <Link
                to="/about"
                // className={
                //   location.pathname === "/about"
                //     ? "nav-item nav-link active"
                //     : "nav-item nav-link"
                // }
              >
               ADMINISTRATION 
              </Link>
              <ul>
                {/* <li>
                  <Link to="#">Governing Body▾</Link>
                  <ul>
                    <li>
                      <Link to={pdf2} target="blank">
                        2017-18
                      </Link>
                    </li>
                    <li>
                      <Link to={pdf1} target="blank">
                        2018-19
                      </Link>
                    </li>
                    <li>
                      <Link to={pdf} target="blank">
                        2019-20
                      </Link>
                    </li>
                    <li>
                      <Link to={pdf3} target="blank">
                        2020-21
                      </Link>
                    </li>
                    <li>
                      <Link to={pdf4} target="blank">
                        2021-22
                      </Link>
                    </li>
                  </ul>
                </li> */}
                <li>
                  <Link to="/aboutTrust">About Trust</Link>
                </li>
               <li>
                  <Link to="/Chairman">Chairman</Link>
                </li>
                <li>
                  <Link to="/secretary">Secretary</Link>
                </li>
                <li>
                  <Link to="/director">Director</Link>
                </li>
                <li>
                  <Link to="/VicePresident">Vice-President</Link>
                </li>
                <li>
                  <Link to="/Administration/principal">Principal</Link>
                </li>

                {/* <li>
                 
                </li> */}
              </ul>

            </li>
            {/* Academic */}
            <li>
              <Link to="#">ACADEMICS </Link>
              <ul>
              <li>
                  <Link to="#">
                  Academic Regulations
                  </Link>
                  <ul>
                    <li>
                      <Link>UG</Link>
                      <ul>
                        <li>
                          <a href={ARUGMR23} target="_blank">MR23</a>
                        </li>
                        <li>
                          <a href={ARUGR20} target="_blank">R20</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link>PG</Link>
                      <ul>
                        <li>
                          <a href={ARPGMTech} target="_blank">M.TECH</a>
                        </li>
                        <li>
                          <a href={ARPGMBA} target="_blank">MBA</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <Link to="">Admissions ▾</Link>
                  <ul>
                    <li>
                      <Link to="/ugpg">UG/PG Intake</Link>
                    </li>
                    <li>
                      <Link to="/admissioncontact">Contact for Admission</Link>
                    </li>
                    <li>
                      <Link to="/admissionprocedure">Admission Procedure</Link>
                    </li>
                    <li>
                      <Link to={fee} target="blank">Fee Structure</Link>
                    </li>
                    <li>
                      <Link to={udpg} target="blank">B-Category Admisson</Link>
                    </li>
                    <li>
                  <Link to={pdf32} target="blank">
                    CAT – B Application
                  </Link>
                </li>
                  </ul>
                </li> */}
                <li>
                  <Link to="/acadamics/acadamicCalender">
                    Academic Calendars
                  </Link>
                </li>
                {/* <li>
                  <Link to="#">Examinations ▾</Link>
                  <ul>
                    <li>
                      <Link to="#">Mock-EAMCET</Link>
                    </li>
                    <li>
                      <Link to="#">Examination Circular</Link>
                    </li>
                    <li>
                      <Link to="#">Previous Question Papers</Link>
                    </li>
                    <li>
                      <Link to="https://jntukresults.edu.in/" target="blank">Results</Link>
                    </li>
                  </ul>
                </li> */}
                <li>
                  <Link to="#">Course Structure & Syllabus</Link>
                  <ul>
                    <li>
                      <Link to="#">UG</Link>

                      <ul>
                        <li>
                          <a href={UGMr23BTECH}  target="_blank">MR23BTECH</a>
                        </li>
                        <li>
                          <a href={UGR20AI}  target="_blank">R20 AI</a>
                        </li>
                        <li>
                          <a href={UGR20CSE}  target="_blank">R20 CSE</a>
                        </li>
                        <li>
                          <a href={UGR20DS}  target="_blank">R20 DS</a>
                        </li>
                        <li>
                          <a href={UGR20ECE}  target="_blank">R20 ECE</a>
                        </li>
                        <li>
                          <a href={UGR20IT}  target="_blank">R20 IT</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#">PG</Link>
                      <ul>
                        <li>
                          <Link onClick={() => handleOpenPdf(MTECHCSE)}target="blank">Mtech(CSE)</Link>
                        </li>
                        <li>
                          <Link onClick={() => handleOpenPdf(MTECHVLSEES)}target="blank">Mtech(VLSE&ES)</Link>
                        </li>
                        <li>
                          <Link onClick={() => handleOpenPdf(MR23MBA)}target="blank">MBA</Link>
                        </li>
                        {/* <li>
                  <Link to="/feestructure">Syllabus</Link>
                </li>
                <li>
                  <Link to="/courseFile">Course File</Link>
                </li> */}
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                {/* <Link>Syllabus</Link> */}
                {/* <ul>
                  <li>
                    <Link>UG</Link>
                    <ul>
                      <li>
                        <a target="_blank" href={SBUGMR23}>MR23</a>
                      </li>
                      <li>
                        <a target="_blank" href={SBUGAI}>R20 AI</a>
                      </li>
                      <li>
                        <a target="_blank" href={SBUGCSE}>R20 CSE</a>
                      </li>
                      <li>
                        <a target="_blank" href={SBUGDS}>R20 DS</a>
                      </li>
                      <li>
                        <a target="_blank" href={SBUGECE}>R20 ECE</a>
                      </li>
                      <li>
                        <a target="_blank" href={SBUGIT}>R20 IT</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link>PG</Link>
                    <ul>
                      <li>
                        <a target="_blank" href={SBPGMTechCSE}>M.TECH CSE</a>
                      </li>
                      <li>
                        <a target="_blank" href={SBPGMTechVLSI}>M.TECH VLSI&ES</a>
                      </li>
                      <li>
                        <a target="_blank" href={SBPGMBAMR23}>MR23 MBA I,II SEM</a>
                      </li>
                    </ul>
                  </li>
                </ul> */}
                </li>

                
            
              </ul>
            </li>



{/* admissions */}

<li>
              <Link
                to="/about"
                // className={
                //   location.pathname === "/about"
                //     ? "nav-item nav-link active"
                //     : "nav-item nav-link"
                // }
              >
               ADMISSIONS
              </Link>
              <ul>
               
                {/* <li>
                  <Link to="#">About Trust</Link>
                </li> */}
                <li>
                  <Link to="/programesofferd">Programs Offered</Link>
                </li>
                <li>
                      <Link to="/admissionprocedure">Admission Procedure</Link>
                    </li>
                    <li>
                      <Link to={fee} target="blank">Fee Structure</Link>
                    </li>
                    {/* <li>
                      <Link to={udpg} target="blank">B-Category Admission</Link>
                    </li> */}
                    <li>
                  <Link to={pdf32} target="blank">
                  B-Category Application
                  </Link>
                </li>

                {/* <li>
                 
                </li> */}
              </ul>

            </li>





            {/* Departments */}
            <li>
              <Link to="#">DEPARTMENTS</Link>
              <ul>
                <li>
                  <Link to="/departments/cse">CSE</Link>
                </li>
                <li>
                  <Link to="/departments/ai&ds">CSE-AI</Link>
                </li>
                <li>
                  <Link to="/departments/cse(al&ml)">CSE-DS</Link>
                </li>
                <li>
                  <Link to="departments/it">IT</Link>
                </li>
                <li>
                  <Link to="/departments/ece">ECE</Link>
                </li>
                {/* <li>
                <Link to="#">CIVIL</Link>
                </li>
                  
                  <li>
                <Link to="#">MECH</Link>
                </li> */}
                <li>
                  <Link to="/departments/s&h">S&H</Link>
                </li>
                {/* <li>
                  <Link to="/departments/mech">M.TECH ▾</Link>
                  <ul>
                    <li>
                      <Link to="/departments/mech">CSE</Link>
                    </li>
                    <li>
                      <Link to="/departments/vlsi">VLSI & ES</Link>
                    </li>
                  </ul>
                </li> */}
                <li>
                  <Link to="/departments/mba">MBA</Link>
                </li>
              </ul>
            </li>

            {/* examinations */}

            <li>
                  <Link to="#">EXAMINATION CELL</Link>
                  <ul>
                    <li>
                      <Link to="/coe">Controller of Examinations (CoE)</Link>
                    </li>
                    <li>
                      <Link to="#">Examination Timetables</Link>
                    </li>
                    <li>
                      <Link to="https://jntukresults.edu.in/" target="blank">Results</Link>
                    </li>
                   
                    <li>
                      <Link to="#">Previous Question Papers</Link>
                      <ul>
                      <li>
                          <a href={PQPBTech} target="_blank">Autonomous</a>
                        </li>
                        <li>
                          <a href={PQPBTech} target="_blank">Jntu-Kakinada</a>
                        </li>
                      {/* <ul>
                        <li>
                          <a href={PQPBTech} target="_blank">B.TECH</a>
                        </li>
                        <li>
                          <a href={PQPMTech} target="_blank">M.TECH</a>
                        </li>
                        <li>
                          <a href={PQPMBA} target="_blank">MBA</a>
                        </li>
                      </ul> */}
                      </ul>
                    </li>
                    <li>
                      <Link to="/admissioncontact">Contact Information</Link>
                    </li>
                  </ul>
                </li>


            {/* Infrastructure */}
            <li>
              <Link to="#">STUDENT CORNER</Link>
              <ul>
                <li>
                  <Link to="/amenities/library">Digital Library</Link>
                </li>
                <li>
                  <Link to="/student/SmceStudentGrivanceCell">Student Grievance Cell</Link>
                </li>
                <li>
                      <Link to="/amenities/e-classRooms">Classrooms</Link>
                    </li>
                    <li>
                      <Link to="/amenities/ictFecilities/lab">
                      Laboratories
                      </Link>
                    </li>
                    <li>
                      <Link to="/amenities/hostels">Hostel</Link>
                    </li>
                    <li>
                      <Link to="/amenities/transport">Transport</Link>
                    </li>
                    <li>
                      <Link to="#">
                      NSS
                      </Link>
                    </li>
                    <li>
                      <Link to="/HealthCenter">Health Center</Link>
                    </li>
                    <li>
                      <Link to="#">Auditorium</Link>
                    </li>
                    <li>
                      <Link to="/amenities/cafeteria">Canteen</Link>
                    </li>
                   
                   
               
              </ul>
            </li>
            {/* Cells */}
            {/* <li>
              <Link to="#">Cells ▾</Link>
              <ul>
                <li>
                  <Link to="#">Training & Placement Cell ▾</Link>
                  <ul>
                    <li>
                      <Link to={pdf8} target="blank">
                        2022 Selected Students
                      </Link>
                    </li>
                    <li>
                      <Link to={pdf7} target="balnk">
                        2021 Selected Students
                      </Link>
                    </li>
                    <li>
                      <Link to={pdf6} target="blank">
                        2020 Selected Students
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to={pdf11} target="blank">
                    Career Guidance Cell
                  </Link>
                </li>
                <li>
                  <Link to={pdf12} target="balnk">
                    ED Cell
                  </Link>
                </li>
                <li>
                  <Link to={pdf13} target="balnk">
                    IIIC Cell
                  </Link>
                </li>
              </ul>
            </li> */}
            {/*AICTE*/}
            {/* <li>
              <li>
                <Link to="#">AICTE ▾</Link>
                <ul>
                  <li>
                    <Link to={pdf17} target="blank">
                      EOA Report 2021-22
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf15} target="blank">
                      EOA Report 2020-21
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf14} target="blank">
                      EOA Report 2019-20
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf16} target="blank">
                      EOA Report 2018-19
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf22} target="blank">
                      EOA Report 2017-18
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf21} target="blank">
                      EOA Report 2016-17
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf20} target="blank">
                      EOA Report 2015-16
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf19} target="blank">
                      EOA Report 2014-15
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf18} target=" blank">
                      EOA Report 2013-14
                    </Link>
                  </li>

                  <li>
                    <Link to={pdf27} target="blank">
                      AICTE 2012-13
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf26} target="blank">
                      AICTE 2010-11
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf25} target="blank">
                      AICTE 2009-10
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf24} target="blank">
                      AICTE Approval 2008-9
                    </Link>
                  </li>
                  <li>
                    <Link to={pdf23} target="blank">
                      AICTE 2006
                    </Link>
                  </li>

                  <li>
                    <Link to="/home">AICTE-EOAS▾</Link>
                    <ul>
                      <li>
                        <Link to={pdf38} target="blank">
                          2021-22
                        </Link>
                      </li>
                      <li>
                        <Link to={pdf37} target="blank">
                          2019-20
                        </Link>
                      </li>
                      <li>
                        <Link to={pdf36} target="blank">
                          2018-19
                        </Link>
                      </li>
                      <li>
                        <Link to={pdf35} target="blank">
                          2017-18
                        </Link>
                      </li>
                      <li>
                        <Link to={pdf38} target="blank">
                          2020-21
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </li> */}
           

            {/* Statutory Committees */}
            {/* <li>
              <Link to="#">STATUTORY DATA ▾</Link>
              <ul>
                <li>
                  <Link to="#">Accreditation ▾</Link>
                  <ul>
                    <li>
                      <Link to={pdf28} target="blank">
                        NBA
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/committees">Committees</Link>
                </li>
              </ul>
            </li> */}


            {/* STUDENT CORNER */}


            <li>
              <Link to="#">STATUTORY BODY </Link>
              <ul>
                
                 
                  
 
                      <li>
                  <Link to="/governingbody">Governing Body</Link>

                </li> 
                    {/* <li>
                      <Link to={pdf28} target="blank">
                        NBA
                      </Link>
                    </li> */}
                  
                  <li>
                  <Link to="/academiccouncil">Academic Council</Link>
                </li>
                <li>
                  <Link to="/bos">Board of Studies (BoS)</Link>
                </li>
                <li>
                  <Link to="/finance">Finance Committee </Link>
                </li>
                <li>
                  <a href={aicte} target="_blank">AICTE</a>
                </li>
                <li>
                  <a href={jntu} target="_blank">JNTUK</a>
                </li>
                <li>
                <a href={nba} target="_blank">
                  
              NBA
            </a>
                </li>
                <li>
                <a href={NaacImg} target="_blank">
              NAAC
            </a>
                </li>
                <li>
                  <a href={iso} target="_blank">ISO</a>
                </li>
                <li>
                  <Link to="/iqac">IQAC</Link>
                </li>
                <li>
                  <Link to="#">IIC</Link>
                </li>
                
                <li>
                  <Link to="/antiRagging">AntiRagging Committee</Link>
                </li>
                <li>
                  <Link to="/committees">College Committees</Link>
                </li>
              </ul>
            </li>

            {/* PLACEMENTS */}

            {/* <li>
              <Link to="#">PLACEMENTS▾</Link>
              <ul>
                <li>
                  <Link to="#">Accreditation ▾</Link>
                  <ul>
                    <li>
                      <Link to={pdf28} target="blank">
                        NBA
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/committees">Committees</Link>
                </li>
              </ul>
            </li> */}

            {/* Naac */}
            <li className="naccli">
              <Link to="#">NAAC </Link>
              
               
                 
                  <ul>
                    <li>
                      <Link to="/naac/naacSsr">AQAR 2022-23</Link>
                    </li>
                    <li>
                      <Link to="/naac/ict">ICT</Link>
                    </li>
                    <li>
                      <Link to="/naac/extendedprofile">Extended Profile</Link>
                    </li>
                    {/* <li>
                      <Link to={pdf33} target="blank">
                        Extended Profile
                      </Link>
                    </li>

                    <li>
                      <Link to="/naac/naacDvv">DVV</Link>
                    </li> */}
                    {/* <li>
                <Link to="#">Extended Profile</Link>
                  </li> */}
                  </ul>
                
              
            </li>
            {/* Grievance */}
            {/* <li>
              <Link to="#">Grievance ▾</Link>
              <ul>
                <li>
                  <Link to="/feedback/aicteFeedback">AICTE Feedback</Link>
                </li>
                <li>
                  <Link to="#">College Grievance</Link>
                </li>
              </ul>
            </li> */}


             {/* Gallery */}
             <li>
              <Link to="#">GALLERY </Link>
              <ul>
                <li>
                  <Link to="/gallery/campusPhotos">
                    Mittapalli Mirace Photos
                  </Link>
                </li>
                <li>
                  <Link to="/gallery/alumniMeet">Alumni-Meet-Photos</Link>
                </li>
                <li>
                  <Link to="/gallery/nss">Nss</Link>
                </li>
                <li>
                  <Link to="/gallery/workshop">OBE Work Shops</Link>
                </li>
              </ul>
            </li>
            {/*Mandatory Disclosure  */}
            {/* <li>
              <Link to="#">Mandatory Disclosure ▾</Link>
              <ul>
                <li>
                  <Link to="#">NIRF Report ▾</Link>
                  <ul>
                    <li>
                      <Link to={pdf30} target="blank">
                        Engineering Report
                      </Link>
                    </li>
                    <li>
                      <Link to={pdf29} target="blank">
                        MBA Report
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}
           
           
          </ul>
        </nav>
      </header>
      <div className="col-sm-12 notification_banner_cnt">
        <marquee behavior="scroll" direction="left" scrollamount="10">
          <div className="d-flex align-items-center gap-3 notification_bg_cnt">
            <NewReleasesIcon
              className="heartbeat"
              id="notification_icon"
              sx={{ fontSize: 30, color: "yellow" }}
            />
            <p className="notification_prg">
              Admissions for the academic year 2024-25 are in progress, for details contact 9000447117
            </p>
          </div>
        </marquee>
      </div>
    </div>
  );
};

export default Menubar;
