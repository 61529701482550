import React from 'react'
import img1 from "../../../assets/aimlimg.jpg";
import img2 from "../../../assets/dl1c.jpg";
import img3 from "../../../assets/aimlimg.jpg";
import img4 from "../../../assets/dl1c.jpg";
import Slider from "react-slick";
import "./Campousphotos.css";


const carousal_images = [
  {
    image: img1,
  },
  {
    image: img2,
  },
  {
    image: img3,
  },
  {
    image: img4,
  },
];
const Nss = () => {
  var settings = {
    dots: true,
    infinite: true,
    // slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnHover: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    };
    return (
      <div className="Main_container_allumini">
        <h1 className="alumini_style">NSS Images</h1>
        <div className="carousal_alumini">
        <Slider {...settings}>
          {carousal_images.map((item, index) => {
            return (
              <div
                key={index}
              >
                <img
                  className="img_alumini"
                  src={item.image}
                  alt="carousel1-img"
                />
              </div>
            );
          })}
        </Slider>
            </div> 
            </div>
    )
  ;
}

export default Nss