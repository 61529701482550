import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import About from "./Components/About/About";
import Contactus from "./Components/Contactus/Contactus";
// import Courses from "./Components/Courses/Courses";
import Error from "./Components/Errorpage/Errorpage";
// import Team from "./Components/Team/Team";
// import Testimonial from "./Components/Testimonial/Testimonial";
import Socialservice from "./Components/Socialservice/Socialservice";
import Admissionprocedure from "./Components/Academics/Admissins/AdmissionProcedur";
import AcadamicCalender from "./Components/Academics/AcademicCalender.js";

// import Departments from "./Components/Departments/CSE";
// import Management from "./Components/Management/Management";
import VisionMision from "./Components/VisionMision/VisionMision";
import Society from "./Components/Society/Society";
import Organogram from "./Components/Organogram/Organogram";
import Principal from "./Components/Principal/Principal";
import Strength from "./Components/Strengths/Strength";
import Incentive from "./Components/Incentives/Incentive";
import Companies from "./Components/Companies/Companies";
import Naac from "./Components/Naac/Naac";
import Dvv from "./Components/Naac/Naac-dvv";
import Placement from "./Components/Placements/Placement";
import Registration from "./Components/Alumini/Registration";
import StudentRegistraion from "./Components/StudentRegistraionForm/StudentRegistration";
// import FeeStructure from "./Components/Academics/Admissins/FeeStructure";

// Departments
import CSEandAIML from "./Components/Departments/CSE(AI&ML)/CSE(AI&ML)";
import EEE from "./Components/Departments/EEE/EEE";
import AIandDS from "./Components/Departments/AI&DS/AI&DS";
import AIML from "./Components/Departments/AI&ML/AI&ML";
import MECH from "./Components/Departments/MECH/MECH";
import SandH from "./Components/Departments/S&H/S&H";
import MBA from "./Components/Departments/MBA/MBA";
import POLYTECHNIC from "./Components/Departments/POLYTECHNIC/POLYTECHNIC";
import CSE from "./Components/Departments/CSE/CSE";
import ECE from "./Components/Departments/ECE/ECE";

import Acadamicregulation from "./Components/AcadamicRegulation/Acadamicregulation";
import Counseling from "./Components/Counseling/Counseling";
import Examinationbranch from "./Components/ExaminationBranch/Examinationbranch";
import Administrationofc from "./Components/Administrationofc/Administrationofc";
import Policies from "./Components/Policies/Policies";
import Login from "./Components/Login/Login";
import Temple from "./Components/Temple/Temple";
import Quarters from "./Components/Amenities/Quarters";
import Eclassroom from "./Components/Amenities/Eclassroom";
import Transportion from "./Components/Amenities/Transportion";
import Hostels from "./Components/Amenities/Hostels";
import Cafeteria from "./Components/Amenities/Cafeteria";
import Sportsgames from "./Components/Amenities/Sportsgames";
import Seminorhall from "./Components/Amenities/Seminorhall";
import Englishlab from "./Components/Amenities/Englishlab";
import Internet from "./Components/Amenities/Internet";
import GrevienceCommittee from "./Components/GrevienceCommittee/GrevienceCommittee";
import DigitalLibrary from "./Components/Amenities/DigitalLibrary";
import Capmousphotos from "./Components/Departments/Galary/Campousphotos";
import AdminstrationGovernigBody from "./Components/Admissionprocedure/AdminstrationGovernigBody";
import MandatoryDiscloser from "./Components/MandatoryDiscloser/Mandatorydiscloser";
import Academic from "./Components/AcadamicCouncil/Academic";
import Library from "./Components/Library/Library";
import Iqac from "./Components/Naac/Iqac";
import Iccommittee from "./Components/IC COMMITTEE/Iccommittee";
import Aictefeedback from "./Components/Amenities/Aictefeedback";
import GrevienceEnquiryForm from "./Components/GrevienceCommittee/GrevienceEnquiryForm";
import GrevienceForm from "./Components/GrevienceCommittee/GrevienceForm";
import Notification from "./Components/Notification/Notification";
import PressNote from "./Components/Notification/PressNote";
import Events from "./Components/Notification/Events"
// import Ict from "./Components/Amenities/IctFecilities/Ict";
import IctLabs from "./Components/Departments/AI&DS/Labs";
import TeacherFeedback from "./Components/GrevienceCommittee/TeacherFeedback";
import StudentFeedback from "./Components/GrevienceCommittee/StudentFeedback";
import EmployeeFeedback from "./Components/GrevienceCommittee/EmployeeFeedback";
import AlluminiFeedback from "./Components/GrevienceCommittee/AlluminiFeedback";
import AdmissionForm from "./Components/Admissionprocedure/AdmissionForm";
// import { ToastContainer, toast } from "react-toastify";
import Chairman from "./Components/Chairman";
import DirectorMessage from "./Components/DirectorMessage";
import SecretaryMessage from"./Components/SecretaryMessage";
import VicePresident from"./Components/VicePresident";
// import AcadamicCalender from "./Components/Admissionprocedure/AcadamicCalender";
import WorkShop from "./Components/Departments/Galary/WorkShop.js";
import AlluminiPhotos from "./Components/Departments/Galary/AlluminiPhotos.js";
import Advertisement from "./Components/CategoryB/Advertisement.js";
import Nss from "./Components/Departments/Galary/Nss.js";

import Infrastructure from "./Components/Strucure";
import Laboratories from "./Components/Laboratories";
import HealthCenter from"./Components/HealthCenter";
import TrainingplacementCell from"./Components/TrainingplacementCell";
import AuditReports from "./Components/AuditReports/AuditReports";
import VLSIES from "./Components/Departments/M.tech/VLSI&ES";
import UGPGIntake from "./Components/Academics/Admissins/UGPG-Intake";
import Admissioncontact from "./Components/Academics/Admissins/AddmisionContact";
import Committees from "./Components/Committees/Committees"; 
import ServiceRules from "./Components/ServiceRules.js";

// committessmenus
import CollegeAcadamic from "./Components/CommitteesMenus/CollegeAcadamic.js";
import IqacCommittee from "./Components/CommitteesMenus/IQAC.js";
import IaccCommittee from "./Components/CommitteesMenus/IACC.js";
import Alumini from "./Components/CommitteesMenus/Alumini.js";
import AntiRagging from "./Components/CommitteesMenus/AntiRagging.js";
import BcSc from "./Components/CommitteesMenus/BcScSt.js";
import Canteen from "./Components/CommitteesMenus/Canteen.js";
import Career from "./Components/CommitteesMenus/Career.js";
import Culture from "./Components/CommitteesMenus/Culture.js";
import Department from "./Components/CommitteesMenus/departmentAssosiation.js";
import Development from "./Components/CommitteesMenus/Development.js";
import Examination from "./Components/CommitteesMenus/Examination.js";
import Finance from "./Components/CommitteesMenus/Finance.js";
import Grevience from "./Components/CommitteesMenus/Grevience.js";
import HealthCenterCommittee from "./Components/CommitteesMenus/Healthcenter.js";
import Hostel from "./Components/CommitteesMenus/Hostel.js";
import Industrial from "./Components/CommitteesMenus/Industrial.js";
import LibraryCommittee from "./Components/CommitteesMenus/Library.js";
import Media from "./Components/CommitteesMenus/Media.js";
import NssCommittee from "./Components/CommitteesMenus/Nss.js";
import Professional from "./Components/CommitteesMenus/PROFESSIONAL SOCIETIES.js";
import RDConsultant from "./Components/CommitteesMenus/R&Dconsultant.js";
import RightToInformation from "./Components/CommitteesMenus/RighttoInformation.js";
import Sports from "./Components/CommitteesMenus/Sports.js";
import Timetable from "./Components/CommitteesMenus/TimeTable.js";
import Training from "./Components/CommitteesMenus/Training.js";
import Transport from "./Components/CommitteesMenus/Transport.js";
import WebsiteCommittee from "./Components/CommitteesMenus/website.js";
import WomenProtection from "./Components/CommitteesMenus/WomenProtection.js";
import CourseFile from "./Components/Academics/CourseFile.js";
import FeeStructure from "./Components/Academics/Admissins/FeeStructure";
import AboutTrust from "./Components/AboutTrust/AboutTrust.js";
import Coe from "./Components/Examinationcell/Coe.js";
import Ict from "./Components/Naac/Ict.js";
import GoverningBody from "./Components/GoverningBody/GoverningBody.js";
import AcademicCouncil from "./Components/AcademicCouncil/AcademicCouncil.js";
import ExtendedProfile from "./Components/Naac/ExtendedProfile.js";
import BOS from "./Components/BOS/BOS.js";
import SmceStudentGrivanceCell from "./Components/SmceStudentGrivanceCell/SmceStudentGrivanceCell.js";

function App() {
  return (
    <Routes>
<Route path="/coe" element={<Coe />} />
<Route path="/courseFile" element={<CourseFile/>} />
<Route path="/feestructure" element={<FeeStructure/>} />
<Route path="/aboutTrust" element={<AboutTrust/>} />


      {/* committes */}
      <Route path="/collegeacadamic" element={<CollegeAcadamic/>} />
      <Route path="/iqacCommittee" element={<IqacCommittee/>} />
      <Route path="/iaccCommittee" element={<IaccCommittee/>} />
      <Route path="/womenProtection" element={<WomenProtection/>} />
      <Route path="/websitecommittee" element={<WebsiteCommittee/>} />
      <Route path="/transport" element={<Transport/>} />
      <Route path="/training" element={<Training/>} />
      <Route path="/timetable" element={<Timetable/>} />
      <Route path="/sports" element={<Sports/>} />
      <Route path="/rightToInformation" element={<RightToInformation/>} />
      <Route path="/RDConsultant" element={<RDConsultant/>} />
      <Route path="/professional" element={<Professional/>} />
      <Route path="/nss" element={<NssCommittee/>} />
      <Route path="/media" element={<Media/>} />
      <Route path="/libraryCommittee" element={<LibraryCommittee/>} />
      <Route path="/industrial" element={<Industrial/>} />
      <Route path="/hostel" element={<Hostel/>} />
      <Route path="/healthCenterCommittee" element={<HealthCenterCommittee/>} />
      <Route path="/grevience" element={<Grevience/>} />
      <Route path="/finance" element={<Finance/>} />
      <Route path="/examination" element={<Examination/>} />
      <Route path="/development" element={<Development/>} />
      <Route path="/department" element={<Department/>} />
      <Route path="/culture" element={<Culture/>} />
      <Route path="/career" element={<Career/>} />
      <Route path="/canteen" element={<Canteen/>} />
      <Route path="/BcSc" element={<BcSc/>} />
      <Route path="/antiRagging" element={<AntiRagging/>} />
      <Route path="/alumini" element={<Alumini/>} />
      <Route path="/iqac" element={<Iqac/>} />







      <Route path="/committees" element={<Committees/>} />
            <Route path="/admissionprocedure" element={<Admissionprocedure/>} />
      <Route path="/servicerules" element={<ServiceRules/>} />
      <Route path="/admissioncontact" element={<Admissioncontact/>} />
      <Route path="/programesofferd" element={<UGPGIntake/>} />
      <Route path="/TrainingplacementCell" element={<TrainingplacementCell/>}/>
      <Route path="/HealthCenter" element={<HealthCenter/>}/>
      <Route path="/Laboratories" element={<Laboratories/>}/>
      <Route path="/infrastructure" element={<Infrastructure/>}/>
      <Route path="/VisionMision" element={<VisionMision/>}/>
      <Route path="/VicePresident" element={<VicePresident/>}/>
      <Route path="/secretary" element={<SecretaryMessage/>}/>
      <Route path="/director" element={<DirectorMessage/>}/>
      <Route path="/Chairman" element={<Chairman/>}/>
      <Route path="/" element={<Home />} />
      <Route path="/auditreports" element ={<AuditReports />}/>
      
      <Route path="/contactus" element={<Contactus />} />
      {/* About */}
      <Route path="/about" element={<About />} />
      <Route path="/Administration/vision" element={<VisionMision />} />
      <Route path="/governingbody" element={<GoverningBody />} />
      <Route path="/Administration/principal" element={<Principal />} />

      {/* Academics */}
      <Route
        path="/acadamics/acadamicCalender"
        element={<AcadamicCalender />}
      />

      {/* Departments */}
      <Route path="/departments/cse" element={<CSE />} />
      <Route path="/departments/cse(al&ml)" element={<CSEandAIML />} />
      <Route path="/departments/ece" element={<ECE />} />
      <Route path="/departments/it" element={<EEE />} />
      <Route path="/departments/ai&ds" element={<AIandDS />} />
      <Route path="/departments/aiml" element={<AIML />} />
      <Route path="/departments/mech" element={<MECH />} />
      <Route path="/departments/s&h" element={<SandH />} />
      <Route path="/departments/mba" element={<MBA />} />
      <Route path="/departments/vlsi" element={<VLSIES />} />
      
      {/* fEEDBACK */}

      <Route path="/feedback/teacherFeedback" element={<TeacherFeedback />} />
      <Route path="/feedback/studentFeedback" element={<StudentFeedback />} />
      <Route path="/feedback/employerFeedback" element={<EmployeeFeedback />} />
      <Route path="/feedback/aluminiFeedback" element={<AlluminiFeedback />} />

      {/* Infrastructure */}

      <Route path="/amenities/library" element={<Library />} />
      <Route path="/amenities/e-classRooms" element={<Eclassroom />} />
      <Route path="/amenities/hostels" element={<Hostels />} />
      <Route path="/amenities/transport" element={<Transportion />} />
      <Route path="/amenities/ictFecilities/lab" element={<IctLabs />} />
      <Route path="/amenities/cafeteria" element={<Cafeteria />} />
      <Route path="/amenities/seminarHalls" element={<Seminorhall />} />

      {/* Cells */}
      <Route path="/placements/placementRecords" element={<Placement />} />

      {/*  Aicte*/}
      <Route path="/feedback/aicteFeedback" element={<Aictefeedback />} />

      {/* Gallery */}

      <Route path="/gallery/campusPhotos" element={<Capmousphotos />} />
      <Route path="/events" element={<Events />} />
   <Route path="/gallery/alumniMeet" element={<AlluminiPhotos />} />
      <Route path="/gallery/workshop" element={<WorkShop />} />
      <Route path="/gallery/nss" element={<Nss />} />

      {/* Statutory Committees */}
      <Route path="/governance/committee" element={<GrevienceCommittee />} />
      <Route path="/academiccouncil" element={<AcademicCouncil />} />
      <Route path="/bos" element={<BOS/>}/>

      {/* Naac */}
      <Route path="/naac/naacSsr" element={<Naac />} />
      <Route path="/naac/naacDvv" element={<Dvv />} />
      <Route path="/naac/iqaReport" element={<Home />} />
      <Route path="/naac/extendedprofile" element={<ExtendedProfile />} />
      
      {/* grevenence */}
      <Route path="/feedback/aicteFeedback" element={<Aictefeedback />} />

      {/* Nirf */}
      <Route path="/nirf/nirfEngineering" element={<Home />} />


      {/* old */}
      {/* Administration */}
      {/* <Route path="/Administration/management" element={<Management />} /> */}
      {/* <Route path="/Administration/society" element={<Society />} /> */}
      
      <Route path="/Administration/quality" element={<VisionMision />} />
      <Route path="/Administration/organogram" element={<Organogram />} />
      
     
      <Route path="/Administration/council" element={<Academic />} />
      <Route path="/Administration/mandatory" element={<MandatoryDiscloser />} />
      <Route path="/Administration/strength" element={<Strength />} />

      {/* Acadamics */}

      <Route
        path="/acadamics/acadamicRegulations"
        element={<Acadamicregulation />}
      />
      
      <Route path="/acadamics/incentives" element={<Incentive />} />
      <Route path="/acadamics/socialService" element={<Socialservice />} />

      {/* Departments */}
      <Route path="/departments/cse" element={<CSE />} />
      <Route path="/departments/cse(al&ml)" element={<CSEandAIML />} />
      <Route path="/departments/ece" element={<ECE />} />
      <Route path="/departments/eee" element={<EEE />} />
      <Route path="/departments/ai&ds" element={<AIandDS />} />
      <Route path="/departments/aiml" element={<AIML />} />
      <Route path="/departments/mech" element={<MECH />} />
      <Route path="/departments/s&h" element={<SandH />} />
      <Route path="/departments/mba" element={<MBA />} />
      <Route path="/departments/polytechnic" element={<POLYTECHNIC />} />

      {/* Admissions */}

      <Route path="/admissions/procedure" element={<Admissionprocedure />} />
      <Route path="/admissions/fee" element={<Acadamicregulation />} />
      <Route path="/admissions/form" element={<AdmissionForm />} />

      {/* Governance */}
      <Route path="/governance/counselling" element={<Counseling />} />
      <Route path="/governance/examination" element={<Examinationbranch />} />
      <Route path="/governance/office" element={<Administrationofc />} />
      <Route path="/governance/policy" element={<Policies />} />
      <Route path="/governance/committee" element={<GrevienceCommittee />} />

      {/* Amenities */}

      <Route path="/amenities/library" element={<Library />} />
      <Route path="/amenities/digitalLibrary" element={<DigitalLibrary />} />
      <Route path="/amenities/internet" element={<Internet />} />
      <Route path="/amenities/englishLabs" element={<Englishlab />} />
      
      
      
      <Route path="/amenities/sports" element={<Sportsgames />} />
      
      
      
      
      <Route path="/amenities/staffQuarters" element={<Quarters />} />
      <Route path="/amenities/temple" element={<Temple />} />

      {/* placements */}

     
      <Route path="/placements/companies" element={<Companies />} />

      {/* NAAC */}

      <Route path="/naac/naacSsr" element={<Naac />} />
      <Route path="/naac/naacDvv" element={<Dvv />} />
      <Route path="/naac/iqac" element={<Iqac />} />
      <Route path="/naac/selfDeclaration" element={<Home />} />
      <Route path="/naac/undertaking" element={<Home />} />
      <Route path="/naac/ict" element={<Ict />} />
      

      {/* NIRF */}

      <Route path="/nirf/nirfOverall" element={<Home />} />
     
      {/* Alumni */}

      <Route path="/alumini/invitation" element={<Home />} />
      <Route path="/alumini/registration" element={<Registration />} />
      

      {/* Student Corner */}

      <Route path="/student/scholarshipNotification" element={<Home />} />
      <Route path="/student/scholarshipRegistration" element={<StudentRegistraion />} />
      <Route path="/student/socialService" element={<Socialservice />} />
      <Route path="/student/SmceStudentGrivanceCell" element={<SmceStudentGrivanceCell />} />

      {/* Greivences */}

      <Route path="/feedback/greivences" element={<GrevienceForm />} />
      <Route path="/feedback/ic-committee" element={<Iccommittee />} />
      
      <Route path="/feedback/enqiryform" element={<GrevienceEnquiryForm />} />
      

      {/* Gallery */}

      
      <Route path="/gallery/notifications" element={<Notification />} />
      <Route path="/notification" element={<Notification />} />
      <Route path="/pressnote" element={<PressNote />} />
      
      <Route path="/gallery/login" element={<Login />} />
     {/*Category B*/}
      <Route path="/CategoryB/Advertisement" element={<Advertisement />} />




      <Route path="*" element={Error} />



    </Routes>
  );
}
export default App;
